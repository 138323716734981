/* @flow */

import React from 'react';
import { Button, UserChildForm } from './';
import type { ReduxFormFields, ReduxFormMeta } from './types';
import { defaultChild } from '../containers/ChildrenFormContainer'; 
import Translations from '../hocs/TranslationsHelper';
import { TextFileButton } from './TextFileButton';
import { CSVToArray } from '../tools/csv';

type Props = {
  fields: ReduxFormFields,
  onRemoveChild: () => mixed,
  meta: ReduxFormMeta
};

/**
 * This component is supposed to be used with redux-form
 * FieldArray, to render an array of children, as a group
 * of UserChildForm
 *
 * @method UserChildFormArray
 * @param  {Array}     fields            - injected by `redux-form`, the fields on the FieldArray from redux-form
 * @param  {function}  onRemoveChild     - function that gets called when a child should be removed
 * @param  {Object}    meta              - object containing some metada from the form
 */
const UserChildFormArray = ({ fields, studentMode, onRemoveChild, meta, maxChildren,translations }: Props) => {

  const { submitting } = meta;

  const addChildButton = (
    <Button onClickButton={() => fields.push(defaultChild() )} type="secondary" className="user-child-form-add-button" disabled={submitting} block>
      <span className="btn-icon pleiq-icon-icon_plus" />
      { translations.t('add_new_profile') }
    </Button>
  );

  return (
    <div>
      {fields.map((name, index, fields) => {
        const child = fields.get(index);
        return (
          <UserChildForm
            key={child.id || index}
            name={name}
            child={child}
            selectedColor={child.color}
            index={index}
            onRemoveChild={onRemoveChild}
            studentMode={studentMode}
            submitting={submitting}
          />
        );
      })}
      {(fields.length < maxChildren || studentMode) && addChildButton}
      {
        studentMode && <TextFileButton label={translations.t('upload_csv_file')} className={"user-child-form-add-button no-margin"} onFileReaded={(text)=>{
          var rows = CSVToArray(text.replaceAll(";",","));
          for(let [name,age] of rows){
            fields.push({...defaultChild(), name , age })
          }

        }}/>
      }
    </div>
  );
};

export default Translations(UserChildFormArray);
